import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Nav,
  Table,
  Modal,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { baseurl } from "Baseurls"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import { TypingIndicator } from "@chatscope/chat-ui-kit-react"
import { ToastContainer, toast } from "react-toastify"

const API_KEY = "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"

const FormWizard = () => {
  const history = useHistory()

  const [form, setform] = useState([])

  const [form1, setform1] = useState({
    followUpAdvice: "",
  })

  const [inputList, setInputList] = useState([{ issue: "", days: "" }])

  const [activeTab, setactiveTab] = useState(1)

  const [diagons, setdiagons] = useState({
    diagnosed: false,
    diagnosedProblem: "",
    diagnosedMonth: "",
    diagnosedYear: "",
  })

  const [bookingslist, setbookingslist] = useState([])

  const [modal_small, setmodal_small] = useState(false)
  const [modal_smalll, setmodal_smalll] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    Clearmedicines()
  }

  const [releatedtexts, setreleatedtexts] = useState([])

  const { age, gender, address, weight, height } = form

  const { diagnosedProblem, diagnosedMonth, diagnosedYear } = diagons

  let texts = ""

  let complaintText = ""

  let pev = ""

  if (diagons.diagnosed == true) {
    pev = `with previously diagnosed case of ${diagnosedProblem} since ${diagnosedYear} years and ${diagnosedMonth} months`
  }

  if (inputList.length > 1 || inputList[0].issue != "") {
    texts = `with complaints of`
    complaintText = inputList
      .map(complaint => `${complaint.issue} for (${complaint.days} days)`)
      .join(", ")
  }

  const profileText = `${age} years ${gender} from ${address} area of India, with weight of ${weight} kgs and height of ${height} cms ${texts} ${pev} ${complaintText} and the brief details for the problems facing is ${bookingslist.briefDetails}`

  const fullText = releatedtexts + "-" + profileText

  // console.log(fullText)

  useEffect(() => {
    GetSettings()
  }, [])

  const GetSettings = () => {
    var token = datas
    axios
      .post(
        "https://api.alludeapp.com/v1/alludeapi/app/doctor/doctorauth/getprompt",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setreleatedtexts(res.data.data.content)
      })
  }

  function tog_smalll() {
    setmodal_smalll(!modal_smalll)
  }

  const [modal_small12, setmodal_small12] = useState(false)
  function tog_small12() {
    setmodal_small12(!modal_small12)
  }

  const handlechange = e => {
    const myData = { ...form }
    myData[e.target.name] = e.target.value
    setform(myData)
  }

  const [passedSteps, setPassedSteps] = useState([1])

  const [activeTab1, setactiveTab1] = useState("1")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function toggleTab(tab) {
    if (activeTab === 1 && tab === 2) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { issue: "", days: "" }])
  }

  // booking details
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  // const [bookinguser, setbookinguser] = useState([])
  const bookingId = sessionStorage.getItem("bookingid")

  const handleInputChange1 = e => {
    const myData = { ...bookingslist }
    myData[e.target.name] = e.target.value
    setbookingslist(myData)
  }

  const handleInputChange2 = e => {
    const myData = { ...form1 }
    myData[e.target.name] = e.target.value
    setform1(myData)
  }

  const handleInputChange12 = e => {
    const myData = { ...diagons }
    myData[e.target.name] = e.target.checked
    setdiagons(myData)
  }

  const handlesetdiagonsChange = e => {
    const myData = { ...diagons }
    myData[e.target.name] = e.target.value
    setdiagons(myData)
  }

  useEffect(() => {
    getBookings()
  }, [])

  const getBookings = () => {
    var token = datas
    const bodydata = {
      id: bookingId,
    }
    axios
      .post(baseurl + "outpatient/getbookingbyid", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setbookingslist(res?.data?.booking)
        setform(res?.data?.booking)
      })
  }

  const submitUseData = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      name: form.userName,
      email: form.email,
      phone: form.phone,
      age: form.age,
      height: form.height,
      grbs: form.grbs,
      temperature: form.temperature,
      weight: form.weight,
      countryCode: form.countryCode,
      gender: form.gender,
      address: form.address,
      bloodPressure: form.bloodPressure,
    }
    axios
      .put(baseurl + "editprofile/" + form._id, bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getBookings()
            toggleTab(activeTab + 1)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  // medicine integrations

  const [medicine, setmedicine] = useState({
    medicineName: "",
    timesPerDay: "",
    dosage: "",
    duration: "",
    instruction: "",
  })

  const [adminmedicine, setadminmedicine] = useState([])
  const [adminmedicinedosing, setadminmedicinedosing] = useState([])
  const [medicine1, setmedicine1] = useState([])

  // console.log(medicine1)

  const [medicine2, setmedicine2] = useState([])

  const handleChangemedicine = e => {
    const myData = { ...medicine }
    myData[e.target.name] = e.target.value
    setmedicine(myData)
  }

  const handleChangemedicine2 = e => {
    const myData = { ...medicine2 }
    myData[e.target.name] = e.target.value
    setmedicine2(myData)
  }
  const getbydata = data => {
    setmedicine2(data)
    setmodal_smalll(true)
  }

  useEffect(() => {
    getMedicine()
    getadminMedicine()
    getmedicineDosings()
  }, [])

  const getMedicine = () => {
    var token = datas
    const bodydata = {
      bookingId: bookingId,
    }
    axios
      .post(baseurl + "prescription/getPrescriptionMedicine", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setmedicine1(res?.data?.medicineData)
      })
  }

  const getadminMedicine = () => {
    var token = datas
    axios
      .post(
        baseurl + "getallmedicines",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setadminmedicine(res.data.medicines)
      })
  }

  const getmedicineDosings = () => {
    var token = datas
    axios
      .post(
        baseurl + "getactivemedicinedosing",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setadminmedicinedosing(res.data.medicineDosings)
      })
  }

  const addMedicines = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      bookingId: bookingslist._id,
      medicineName: medicine.medicineName,
      timesPerDay: medicine.timesPerDay,
      dosage: medicine.dosage,
      duration: medicine.duration,
      type: medicine.type,
      instruction: medicine.instruction,
    }
    axios
      .post(baseurl + "prescription/addprescriptionmedicine", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_small(false)
            getMedicine()
            Clearmedicines()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const editMedicines = e => {
    e.preventDefault()
    var token = datas
    const bodydata = {
      bookingId: bookingslist._id,
      medicineName: medicine2.medicineName,
      timesPerDay: medicine2.timesPerDay,
      dosage: medicine2.dosage,
      duration: medicine2.duration,
      type: medicine2.type,
      instruction: medicine2.instruction,
    }
    axios
      .put(
        baseurl + "prescription/updateprescriptiomedicine/" + medicine2._id,
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            setmodal_smalll(false)
            getMedicine()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const DeleteMedicines = data => {
    var token = datas
    axios
      .delete(baseurl + "prescription/deleteprescriptionmedicine/" + data._id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            getMedicine()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you want remove this medicine")
    if (confirmBox === true) {
      DeleteMedicines(data)
    }
  }

  const Clearmedicines = () => {
    setmedicine({
      medicineName: "",
      timesPerDay: "",
      dosage: "",
      duration: "",
      instruction: "",
    })
  }

  // gpt integration
  const [aidata, setaidata] = useState([])

  const [text1, setText1] = useState([])

  useEffect(() => {
    setText1(aidata.map(data => data.message))
  }, [aidata])
  useEffect(() => {
    setEditedText(text1)
  }, [text1])

  const [editedText, setEditedText] = useState([...text1])

  const [gptdatas, setgptdata] = useState([])

  console.log(gptdatas)

  const [messages, setMessages] = useState([])

  const [isTyping, setIsTyping] = useState(false)

  const [tokensUsed, setTokensUsed] = useState(0)

  const handleSendRequest = async message => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    }

    setMessages(prevMessages => [...prevMessages, newMessage])
    setaidata([newMessage])
    setIsTyping(true)

    try {
      const response = await processMessageToChatGPT([...messages, newMessage])
      const content = response.choices[0]?.message?.content
      const gpttokens = response.usage?.total_tokens
      sessionStorage.setItem("gpttokens", gpttokens)
      setTokensUsed(gpttokens)
      const bodydata = {
        completion_tokens: response.usage?.completion_tokens,
        prompt_tokens: response.usage?.prompt_tokens,
        total_tokens: response.usage?.total_tokens,
        bookingId: bookingId,
      }
      var token = datas
      axios
        .post(baseurl + "doctorauth/addsearchcount", bodydata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(res => {
          console.log(res)
        })

      if (content) {
        const chatGPTResponse = {
          message: content.replace(/\*/g, ""),
          sender: "ChatGPT",
        }
        setMessages(prevMessages => [...prevMessages, chatGPTResponse])
        setaidata([chatGPTResponse])
        setgptdata([])
        getTokensCount()
      }
    } catch (error) {
      console.error("Error processing message:", error)
    } finally {
      setIsTyping(false)
    }
  }

  const getTokensCount = () => {
    var token = datas
    const bodydata = {
      bookingId: bookingslist._id,
    }
    axios
      .post(baseurl + "doctorauth/addsearchcount", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        console.log(res)
      })
  }

  const senduserdata = {
    name: form.userName,
    gender: form.gender,
    age: form.age,
    address: form.address,
    bloodPressure: form.bloodPressure,
    problem: bookingslist.problem,
    diagnosedProblem: diagons.diagnosedProblem,
    diagnosedMonth: diagons.diagnosedMonth,
    diagnosedYear: diagons.diagnosedYear,
    HealthIssue: inputList,
    briefDetails: bookingslist.briefDetails,
  }

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = chatMessages.map(messageObject => {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user"
      return {
        role,
        content: messageObject.message || JSON.stringify(senduserdata),
      }
    })

   
    const apiRequestBody = {
      // model: "gpt-3.5-turbo",
      // temperature: 0.7,
      model: "gpt-4-turbo",
      messages: messages,
      max_tokens: 270, // Limit the response to 100 tokens
      temperature: 0.9, // Increase creativity, but not too much
      top_p: 0.9, // Consider only the top 90% of tokens by cumulative probability
      frequency_penalty: 0.5, // Encourage diversity by penalizing frequent tokens
      presence_penalty: 0.0, // No penalty for tokens present in the prompt
      messages: [
        { role: "user", content: "Say this is a test!" + fullText } 
      ],
    }

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
    return response.json()
  }

  const firstcallingallude = () => {
    toggleTab(activeTab + 1)
    handleSendRequest()
  }

  const handleEdit = e => {
    const newValue = e.target.value
    const newValue1 = newValue.split("\n")
    setgptdata(newValue1)
  }

  // const lineData = (data, i) => {
  //   setgptdata(prevAidata => {
  //     const newData = [...prevAidata]
  //     const messageIndex = prevAidata.indexOf(data)
  //     if (messageIndex == -1) {
  //       newData[i] = data
  //     } else {
  //       newData.splice(messageIndex, 1)
  //     }
  //     return newData
  //   })
  // }

  const lineData = (data, i) => {
    setgptdata(prevAidata => {
      const newData = [...prevAidata]
      const messageIndex = prevAidata.indexOf(data)
      if (messageIndex === -1) {
        if (data !== undefined && data !== null && data !== "") {
          newData[i] = data
        }
      } else {
        newData.splice(messageIndex, 1)
      }
      // Check if item is not undefined, not null, and not empty
      if (
        newData[i] !== undefined &&
        newData[i] !== null &&
        newData[i] !== ""
      ) {
        // Do something with newData[i] if needed
      }
      return newData
    })
  }

  const submitConsoldata = e => {
    e.preventDefault()

    const filteredGptdatas = gptdatas.filter(item => {
      // Check if item is not undefined and not null
      if (item !== undefined && item !== null) {
        // Use trim() method only if item is not undefined or null
        return item.trim() !== ""
      } else {
        return false // Exclude undefined or null items
      }
    })

    var token = datas
    const bodydata = {
      bookingId: bookingslist._id,
      problems: bookingslist.problem,
      briefDetails: bookingslist.briefDetails,
      diagnosed: bookingslist.diagnosed,
      diagnosedProblem: diagons.diagnosedProblem,
      diagnosedMonth: diagons.diagnosedMonth,
      diagnosedYear: diagons.diagnosedYear,
      healthIssues: inputList,
      alludeSuggestion: filteredGptdatas,
      followUpAdvice: form1.followUpAdvice,
    }
    axios
      .post(baseurl + "prescription/addprescription", bodydata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast.success(res.data.message)
            history.push("/consulted")
            // setTimeout(() => {
            //   history.push("/consulted")
            // }, 2000)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  let investigationsContent = null

  if (medicine1.length !== 0) {
    investigationsContent = (
      <div className="mt-3">
        <h5 style={{ color: "black" }}>
          <b>
            <img
              src="https://api.alludeapp.com/uploads/rx.jpg"
              alt="test"
              height="30px"
            />
          </b>
        </h5>
        {medicine1.map((datas, indexs) => (
          <div key={indexs} className="mb-3" style={{ color: "black" }}>
            <b>
              {indexs + 1}.{datas.medicineName} {data.composition == undefined || data.composition == null ?"":(data.composition)}
            </b>
            <br />
            <span>Frequency: {datas.timesPerDay}</span>
            <br />
            <span>Dosage: {datas.dosage}</span>
            <br />
            <span>
              Duration: {datas.duration} {datas.type}
            </span>
            <br />
            <span>Drug Instruction: {datas.instruction}</span>
          </div>
        ))}
        <b style={{ color: "black" }}>Investigations</b>
      </div>
    )
  } else if (medicine1.length == 0) {
    investigationsContent = (<b style={{ color: "black" }}>Investigations</b>)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Allude Says" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">01.</span> Edit Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number ms-2">02</span> Complaints
                          </NavLink>
                        </NavItem>

                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">03</span> Select Detail
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">04</span> Prescription
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 5 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 5 })}
                            onClick={() => {
                              setactiveTab(5)
                            }}
                            disabled={!(passedSteps || []).includes(5)}
                          >
                            <span className="number">05</span> View
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix mt-4">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <Form
                            onSubmit={e => {
                              submitUseData(e)
                            }}
                          >
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Name <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Name"
                                    required
                                    value={form.userName}
                                    name="userName"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    Age (Years.months)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Age"
                                    required
                                    value={form.age}
                                    name="age"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    Gender<span className="text-danger">*</span>
                                  </Label>
                                  <select
                                    value={form.gender}
                                    name="gender"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                    className="form-select"
                                  >
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    Weight (kgs)
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter weight"
                                    value={form.weight}
                                    name="weight"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    Height (cms)
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Height"
                                    value={form.height}
                                    name="height"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    BP
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter BP"
                                    value={form.bloodPressure}
                                    name="bloodPressure"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    Temperature
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Temperature"
                                    value={form.temperature}
                                    name="temperature"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-2">
                                  <Label for="basicpill-firstname-input1">
                                    GRBS
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter grbs"
                                    value={form.grbs}
                                    name="grbs"
                                    onChange={e => {
                                      handlechange(e)
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="text-end">
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                disabled
                                style={{ width: "100px", margin: "5px" }}
                                type="button"
                                outline
                                color="danger"
                              >
                                Previous
                              </Button>
                              <Button
                                style={{ width: "100px", margin: "5px" }}
                                type="submit"
                                outline
                                color="primary"
                              >
                                Next
                              </Button>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col md={4}>
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col md="6"></Col>
                                        <Col md="6">
                                          <b
                                            style={{ float: "right" }}
                                            className="text-primary text-right"
                                          >
                                            Type :
                                            {bookingslist.consultationType}
                                          </b>
                                        </Col>
                                      </Row>
                                      <ul className="list-unstyled mt-4">
                                        <li>
                                          <div className="d-flex">
                                            <i className="bx bx-user-circle text-primary fs-4"></i>
                                            <div className="ms-3">
                                              <h6 className="fs-14 mb-2">
                                                Name
                                              </h6>
                                              <p className="text-muted fs-14 mb-0">
                                                {form.userName}
                                              </p>
                                            </div>
                                          </div>
                                        </li>

                                        <li className="mt-3">
                                          <div className="d-flex">
                                            <i className="bx bx-error-circle text-primary fs-4"></i>
                                            <div className="ms-3">
                                              <h6 className="fs-14 mb-2">
                                                Gender / Age
                                              </h6>
                                              <p className="text-muted fs-14 text-break mb-0">
                                                {form.gender} / {form.age}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li className="mt-3">
                                          <div className="d-flex">
                                            <i className="bx bx-error-circle text-primary fs-4"></i>
                                            <div className="ms-3">
                                              <h6 className="fs-14 mb-2">
                                                Height / Weight
                                              </h6>
                                              <p className="text-muted fs-14 text-break mb-0">
                                                {form.height} (cms) /
                                                {form.weight} (kgs)
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li className="mt-3">
                                          <div className="d-flex">
                                            <i className="bx bx-droplet text-primary fs-4"></i>
                                            <div className="ms-3">
                                              <h6 className="fs-14 mb-2">
                                                Blood pressure
                                              </h6>
                                              <p className="text-muted fs-14 text-break mb-0">
                                                {form.bloodPressure}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li className="mt-3">
                                          <div className="d-flex">
                                            <i className="bx bx-map text-primary fs-4"></i>
                                            <div className="ms-3">
                                              <h6 className="fs-14 mb-2">
                                                Address
                                              </h6>
                                              <p className="text-muted fs-14 mb-0">
                                                {form.address}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col md={8}>
                                  <div>
                                    <Form className="mt-2">
                                      <Row>
                                        <Col md="5">
                                          <h5>Diagnosed Patient: </h5>
                                        </Col>
                                        <Col md="2">
                                          <div className="form-check form-switch form-switch-md">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id="customSwitchsizemd"
                                              defaultChecked={diagons.diagnosed}
                                              name="diagnosed"
                                              onChange={e =>
                                                handleInputChange12(e)
                                              }
                                            />
                                          </div>
                                        </Col>
                                        {diagons.diagnosed == true ? (
                                          <>
                                            <Col md="12">
                                              <Label>Diagnosed c/o</Label>
                                              <textarea
                                                value={diagons.diagnosedProblem}
                                                name="diagnosedProblem"
                                                onChange={e =>
                                                  handlesetdiagonsChange(e)
                                                }
                                                className="form-control"
                                                placeholder="Enter Problem"
                                              />
                                            </Col>
                                            <Col md="4">
                                              <Label>Months</Label>
                                              <Input
                                                value={diagons.diagnosedMonth}
                                                name="diagnosedMonth"
                                                onChange={e =>
                                                  handlesetdiagonsChange(e)
                                                }
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Months"
                                              />
                                            </Col>
                                            <Col md="4">
                                              <Label>Year</Label>
                                              <Input
                                                value={diagons.diagnosedYear}
                                                name="diagnosedYear"
                                                onChange={e =>
                                                  handlesetdiagonsChange(e)
                                                }
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Year"
                                              />
                                            </Col>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </Row>
                                    </Form>

                                    <Row className="mt-2">
                                      {inputList.map((x, i) => {
                                        return (
                                          <>
                                            <div className="mb-3">
                                              <div key={i} className="box row">
                                                <Col
                                                  md="4"
                                                  sm="12"
                                                  className="mb-1"
                                                >
                                                  <Label>Complaints</Label>
                                                  <Input
                                                    type="text"
                                                    name="issue"
                                                    placeholder="Enter Complaints"
                                                    value={x.issue}
                                                    onChange={e =>
                                                      handleInputChange(e, i)
                                                    }
                                                  />
                                                </Col>
                                                <Col
                                                  md="4"
                                                  sm="12"
                                                  className="mb-1"
                                                >
                                                  <Label>Days</Label>
                                                  <Input
                                                    type="number"
                                                    name="days"
                                                    placeholder="Enter Days"
                                                    value={x.days}
                                                    onChange={e =>
                                                      handleInputChange(e, i)
                                                    }
                                                  />
                                                </Col>
                                                <Col sm="4">
                                                  <Label className="mt-1"></Label>
                                                  <div className="btn-box">
                                                    {inputList.length - 1 ===
                                                      i && (
                                                      <button
                                                        className="btn btn-sm btn-outline-info m-1 mt-3"
                                                        onClick={handleAddClick}
                                                      >
                                                        Add
                                                        <i className="bx bx-plus-circle"></i>
                                                      </button>
                                                    )}
                                                    {inputList.length !== 1 && (
                                                      <button
                                                        className="mr10 btn btn-outline-danger btn-sm m-1 mt-3"
                                                        type="button"
                                                        onClick={() =>
                                                          handleRemoveClick(i)
                                                        }
                                                      >
                                                        Remove
                                                        <i className="bx bx-x-circle"></i>
                                                      </button>
                                                    )}
                                                  </div>
                                                </Col>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      })}
                                    </Row>
                                    <Row>
                                      <Col md="12" sm="12" className="mb-1">
                                        <Label>
                                          Brief Clinical Details / HOPI
                                        </Label>
                                        <textarea
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter Brief Clinical Details / HOPI"
                                          value={bookingslist.briefDetails}
                                          name="briefDetails"
                                          onChange={e => handleInputChange1(e)}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>

                              <div className="text-end">
                                <Button
                                  onClick={() => {
                                    toggleTab(activeTab - 1)
                                  }}
                                  style={{ width: "100px", margin: "5px" }}
                                  type="button"
                                  outline
                                  color="danger"
                                >
                                  Previous
                                </Button>
                                <Button
                                  onClick={() => {
                                    firstcallingallude()
                                  }}
                                  style={{ width: "100px", margin: "5px" }}
                                  type="button"
                                  outline
                                  color="primary"
                                >
                                  Next
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </TabPane>

                        <TabPane tabId={3}>
                          <div>
                            <h4 className="mb-4 pb-4 text-primary">
                              {isTyping ? (
                                // <TypingIndicator content="Allude Ai is Analysing..." />
                                <TypingIndicator content="Allude says..." />
                              ) : null}
                            </h4>
                            {editedText.map((message, index) => (
                              <div key={index}>
                                {message?.split("\n").map((paragraph, i) => {
                                  if (paragraph !== "") {
                                    return (
                                      <div
                                        key={i}
                                        style={{ paddingBottom: "15px" }}
                                      >
                                        <input
                                          onClick={() => {
                                            lineData(paragraph, i)
                                          }}
                                          style={{ marginRight: "20px" }}
                                          type="checkbox"
                                        />
                                        {paragraph ===
                                          "Diagnosis/Most probable diagnosis" ||
                                        paragraph ===
                                          "Diagnosis/Most Probable Diagnosis" ||
                                        paragraph ===
                                          "Differential diagnoses" ||
                                        paragraph === "Investigations" ||
                                        paragraph === "Instructions" ||
                                        paragraph === "Treatment" ? (
                                          <strong>{paragraph}</strong>
                                        ) : (
                                          paragraph
                                        )}
                                        <br />
                                      </div>
                                    )
                                  }
                                })}
                                <br></br>
                              </div>
                            ))}
                          </div>
                          <div className="text-end">
                            <Button
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                              style={{ width: "100px", margin: "5px" }}
                              outline
                              color="danger"
                            >
                              Previous
                            </Button>
                            <Button
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                              style={{ width: "100px", margin: "5px" }}
                              outline
                              color="primary"
                            >
                              Next
                            </Button>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <Form>
                            <div>
                              <Row>
                                <Col md="7">
                                  <Nav
                                    pills
                                    className="navtab-bg nav-justified"
                                  >
                                    <NavItem className="border border-primary rounded m-1">
                                      <NavLink
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        className={classnames({
                                          active: activeTab1 === "1",
                                        })}
                                        onClick={() => {
                                          toggle1("1")
                                        }}
                                      >
                                        Clinical Details
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="border border-primary rounded m-1">
                                      <NavLink
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        className={classnames({
                                          active: activeTab1 === "2",
                                        })}
                                        onClick={() => {
                                          toggle1("2")
                                        }}
                                      >
                                        Drugs Rx
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </Col>
                              </Row>
                              <TabContent
                                activeTab={activeTab1}
                                className="p-3 text-muted"
                              >
                                <TabPane tabId="1">
                                  <Row>
                                    <Col md="4">
                                      <div className="mt-3">
                                        <Label>Follow Up Advice :</Label>
                                        <Input
                                          type="text"
                                          name="followUpAdvice"
                                          placeholder="Enter Follow Up Advice"
                                          value={form1.followUpAdvice}
                                          onChange={e => handleInputChange2(e)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <div className="mt-3">
                                      <Label>Clinical Details</Label>
                                      <textarea
                                        className="form-control"
                                        value={gptdatas.join("\n")}
                                        rows={25}
                                        name="message"
                                        onChange={e => handleEdit(e)}
                                      />
                                    </div>
                                  </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row>
                                    <Col md="12">
                                      <div className="text-end">
                                        <Button
                                          onClick={tog_small}
                                          size="sm"
                                          color="info"
                                        >
                                          Add Medicine
                                          <i className="bx bx-plus-circle" />
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Table
                                    hover
                                    className="table table-bordered mt-2"
                                  >
                                    <thead>
                                      <tr className="text-center">
                                        <th>S.No</th>
                                        <th>Medicine name</th>
                                        <th>Frequency</th>
                                        <th>Dosage</th>
                                        <th>Duration</th>
                                        <th>Drug Instruction</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {medicine1.map((data, key) => (
                                        <tr key={key} className="text-center">
                                          <td>{key + 1}</td>
                                          <td>{data.medicineName}</td>
                                          <td>{data.timesPerDay}</td>
                                          <td>{data.dosage} </td>
                                          <td>
                                            {data.duration} {data.type}
                                            {data.duration == "0" ||
                                            data.duration == "1" ? (
                                              <></>
                                            ) : (
                                              <>s</>
                                            )}
                                          </td>
                                          <td>{data.instruction} </td>
                                          <td>
                                            <Button
                                              size="sm"
                                              color="warning"
                                              className="m-1"
                                              outline
                                              onClick={() => {
                                                getbydata(data)
                                              }}
                                            >
                                              <i className="bx bx-edit"></i>
                                            </Button>
                                            <Button
                                              size="sm"
                                              color="danger"
                                              className="m-1"
                                              outline
                                              onClick={() => {
                                                manageDelete(data)
                                              }}
                                            >
                                              <i className="bx bx-trash"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </TabPane>
                              </TabContent>
                            </div>
                            <div className="text-end">
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                style={{ width: "100px", margin: "5px" }}
                                outline
                                color="danger"
                              >
                                Previous
                              </Button>
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                                style={{ width: "100px", margin: "5px" }}
                                outline
                                color="primary"
                              >
                                Next
                              </Button>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={5}>
                          <Form
                            onSubmit={e => {
                              submitConsoldata(e)
                            }}
                          >
                            <div className="container">
                              <div>
                                <p style={{ margin: "2px", color: "black" }}>
                                  <b>Name : </b> {form.userName}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Age/Sex : </b> {form.age}Yrs /{form.gender}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b> Weight/Ht : </b> {form.weight} (Kgs) /
                                  {form.height} (Cms)
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Location : </b>
                                  {form.address}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Date/Time : </b>
                                  {form.date}, {form.time}
                                </p>

                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Temperature/Grbs : </b>
                                  {form.temperature} / {form.grbs}
                                </p>
                              </div>

                              <div className="mt-4">
                                <p style={{ color: "black" }}>
                                  {diagons.diagnosed == true ? (
                                    <>
                                      {diagons.diagnosedProblem == "" ? (
                                        <></>
                                      ) : (
                                        <>
                                          <p style={{ color: "black" }}>
                                            <b>Diagnosed Case Of : </b>
                                          </p>
                                          {diagons.diagnosedProblem}
                                        </>
                                      )}

                                      {diagons.diagnosedMonth == "" ||
                                      diagons.diagnosedYear == "" ? (
                                        <></>
                                      ) : (
                                        <>
                                          <b>, Since:</b>
                                        </>
                                      )}

                                      {diagons.diagnosedYear == "" ? (
                                        <></>
                                      ) : (
                                        <>, {diagons.diagnosedYear} years</>
                                      )}

                                      {diagons.diagnosedMonth == "" ? (
                                        <></>
                                      ) : (
                                        <>, {diagons.diagnosedMonth} months</>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>

                              {inputList[0]?.issue == "" &&
                              inputList[0]?.days == "" ? (
                                <></>
                              ) : (
                                <div className="mt-3">
                                  <p style={{ color: "black" }}>
                                    <b>Complaints :</b>
                                  </p>
                                  <ol>
                                    {inputList.map((data, key) => (
                                      <>
                                        {data.issue != "" || data.days != "" ? (
                                          <li key={key}>
                                            {data.issue == "" ? (
                                              ""
                                            ) : (
                                              <>
                                                <b>{data.issue}</b>
                                              </>
                                            )}

                                            {data.days == "" ? (
                                              ""
                                            ) : (
                                              <>Since {data.days} days</>
                                            )}
                                          </li>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ))}
                                  </ol>
                                </div>
                              )}

                              {bookingslist.briefDetails == undefined ||
                              bookingslist.briefDetails == "" ? (
                                <></>
                              ) : (
                                <>
                                  <div className="mt-3">
                                    <p style={{ color: "black" }}>
                                      <b>Brief Clinical Details:</b>
                                    </p>
                                    <p>{bookingslist.briefDetails} </p>
                                  </div>
                                </>
                              )}

                              {/* <div>
                                {gptdatas?.map((data, index) => (
                                  <div key={index}>{data}</div>
                                ))}
                              </div> */}

                              {/* {medicine1.length == 0 ? (
                                  <></>
                                ) : (
                                  <>
                                    <div className="mt-3">
                                      <h5 style={{ color: "black" }}>
                                        <b>
                                          <img
                                            src="https://api.alludeapp.com/uploads/rx.jpg"
                                            alt="test"
                                            height="30px"
                                          ></img>
                                        </b>
                                      </h5>

                                      {medicine1.map((data, index) => (
                                        <div
                                          key={index}
                                          className="mb-3 "
                                          style={{ color: "black" }}
                                        >
                                          <b>
                                            {index + 1}.{data.medicineName} (
                                            {data.composition})
                                          </b>
                                          <br />
                                          <span>
                                            Frequency : {data.timesPerDay}
                                          </span>
                                          <br />
                                          <span>Dosage : {data.dosage}</span>
                                          <br />
                                          <span>
                                            Duration : {data.duration} {data.type}
                                          </span>
                                          <br />
                                          <span>
                                            Drug Instruction :{data.instruction}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )} */}

                              <div>
                                <>
                                  {gptdatas?.map((data, index) => (
                                    <div
                                      key={index}
                                      style={{ paddingBottom: "10px" }}
                                    >
                                      {data ==
                                        "Diagnosis/Most probable diagnosis" ||
                                      data ==
                                        "Diagnosis/Most Probable Diagnosis" ||
                                      data == "Differential diagnoses" ||
                                      data == "Instructions" ||
                                      data == "Treatment" ? (
                                        <b
                                          style={{
                                            color: "black",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {data}
                                        </b>
                                      ) : data == "Investigations" ? (
                                        investigationsContent
                                      ) : (
                                        data
                                      )}
                                    </div>
                                  ))}
                                </>
                              </div>

                              {form1.followUpAdvice == "" ? (
                                <></>
                              ) : (
                                <>
                                  <div className="mt-3">
                                    <p style={{ color: "black" }}>
                                      <b>Follow Up Advice : </b>
                                    </p>
                                    <p>{form1.followUpAdvice} </p>
                                  </div>
                                </>
                              )}
                            </div>

                            <div style={{ float: "right" }}>
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                style={{ margin: "2px" }}
                                outline
                                color="danger"
                              >
                                Previous
                              </Button>
                              <Button
                                style={{ margin: "2px" }}
                                outline
                                color="success"
                              >
                                Send To Patient
                              </Button>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Medicine</h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                addMedicines(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <label htmlFor="browsers">Medicine Name:</label>
                    <input
                      required
                      placeholder="Medicine Name"
                      list="browsers"
                      id="browserChoice"
                      className="form-control"
                      name="medicineName"
                      value={medicine.medicineName}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    />
                    <datalist id="browsers">
                      {adminmedicine.map((data, key) => (
                        <option key={key} value={`${data.brandName} (${data.composition})`} />
                        // <option key={key} value={data.drugName} />
                      ))}
                    </datalist>
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <label htmlFor="browsers123">Frequency :</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter Frequency"
                      list="browsers123"
                      id="browserChoice"
                      className="form-control"
                      name="timesPerDay"
                      value={medicine.timesPerDay}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    />
                    <datalist id="browsers123">
                      {adminmedicinedosing.map((data, key) => (
                        <option key={key} value={data.dosing} />
                      ))}
                    </datalist>
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <label htmlFor="browsers1234">Dosage :</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter Dosage"
                      list="browsers1234"
                      id="browserChoice"
                      className="form-control"
                      name="dosage"
                      value={medicine.dosage}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    />
                    <datalist id="browsers1234">
                      <option value="1ml" />
                      <option value="2ml" />
                      <option value="3ml" />
                      <option value="4ml" />
                      <option value="5ml" />
                      <option value="6ml" />
                      <option value="7ml" />
                      <option value="8ml" />
                      <option value="9ml" />
                      <option value="10ml" />
                    </datalist>
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label>
                      Duration <span className="text-danger">*</span>
                    </Label>
                    <Input
                      required
                      name="duration"
                      value={medicine.duration}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                      type="text"
                      placeholder="Enter Duration"
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label>
                      Duration Type <span className="text-danger">*</span>
                    </Label>
                    <select
                      className="form-select"
                      required
                      name="type"
                      value={medicine.type}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <Label>Drug Instruction</Label>
                    <textarea
                      name="instruction"
                      value={medicine.instruction}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                      className="form-control"
                      type="text"
                      placeholder="Enter Drug Instruction"
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="bx bx-x-circle"></i>
                </Button>
                <Button color="success" type="submit" className="m-1">
                  Submit <i className="bx bx-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_smalll}
          toggle={() => {
            tog_smalll()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Edit Medicine</h5>
            <button
              onClick={() => {
                setmodal_smalll(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                editMedicines(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <label htmlFor="browsers">Medicine Name:</label>
                    <input
                      placeholder="Medicine Name"
                      list="browsers"
                      id="browserChoice"
                      className="form-control"
                      name="medicineName"
                      value={medicine2.medicineName}
                      onChange={e => {
                        handleChangemedicine2(e)
                      }}
                    />
                    <datalist id="browsers">
                      {adminmedicine.map((data, key) => (
                        <option key={key} value={data.drugName} />
                      ))}
                    </datalist>
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <label htmlFor="browsers123">Frequency :</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter Frequency"
                      list="browsers123"
                      id="browserChoice"
                      className="form-control"
                      name="timesPerDay"
                      value={medicine2.timesPerDay}
                      onChange={e => {
                        handleChangemedicine2(e)
                      }}
                    />
                    <datalist id="browsers123">
                      {adminmedicinedosing.map((data, key) => (
                        <option key={key} value={data.dosing} />
                      ))}
                    </datalist>
                  </div>
                </Col>

                <Col md="12">
                  <div className="mb-3">
                    <label htmlFor="browsers1234">Dosage :</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter Dosage"
                      list="browsers1234"
                      id="browserChoice"
                      className="form-control"
                      name="dosage"
                      value={medicine2.dosage}
                      onChange={e => {
                        handleChangemedicine2(e)
                      }}
                    />
                    <datalist id="browsers1234">
                      <option value="1ml" />
                      <option value="2ml" />
                      <option value="3ml" />
                      <option value="4ml" />
                      <option value="5ml" />
                      <option value="6ml" />
                      <option value="7ml" />
                      <option value="8ml" />
                      <option value="9ml" />
                      <option value="10ml" />
                    </datalist>
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <Label>
                      Duration <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="duration"
                      value={medicine2.duration}
                      onChange={e => {
                        handleChangemedicine2(e)
                      }}
                      type="text"
                      placeholder="Enter Duration"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <Label>
                      Duration Type <span className="text-danger">*</span>
                    </Label>
                    <select
                      className="form-select"
                      required
                      name="type"
                      value={medicine2.type}
                      onChange={e => {
                        handleChangemedicine2(e)
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>
                </Col>

                <Col md="12">
                  <div className="mb-3">
                    <Label>Instruction</Label>
                    <textarea
                      name="instruction"
                      value={medicine2.instruction}
                      onChange={e => {
                        handleChangemedicine2(e)
                      }}
                      className="form-control"
                      type="text"
                      placeholder="Enter Instruction"
                    />
                  </div>
                </Col>
              </Row>

              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_smalll(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="bx bx-x-circle"></i>
                </Button>
                <Button color="success" type="submit" className="m-1">
                  Submit <i className="bx bx-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_small12}
          toggle={() => {
            tog_small12()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Diagnosed case</h5>
            <button
              onClick={() => {
                setmodal_small12(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <Row>
                <Col md="5">
                  <h5>Diagnosed Patient: </h5>
                </Col>
                <Col md="2">
                  <div className="form-check form-switch form-switch-md">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitchsizemd"
                      defaultChecked={diagons.diagnosed}
                      name="diagnosed"
                      onChange={e => handleInputChange12(e)}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <Label>Diagnosed case</Label>
                  <textarea
                    value={diagons.diagnosedProblem}
                    disabled={!diagons.diagnosed}
                    name="diagnosedProblem"
                    onChange={e => handlesetdiagonsChange(e)}
                    className="form-control"
                    placeholder="Enter Problem"
                  />
                </Col>
                <Col md="12">
                  <Label>Since</Label>
                  <Input
                    value={diagons.diagnosedDays}
                    disabled={!diagons.diagnosed}
                    name="diagnosedDays"
                    onChange={e => handlesetdiagonsChange(e)}
                    type="number"
                    className="form-control"
                    placeholder="Enter Days"
                  />
                </Col>
              </Row>
            </Form>

            <div className="mt-3" style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_small12(false)
                }}
                color="danger"
                type="button"
                className="m-1"
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>

              <Button
                onClick={() => {
                  setmodal_small12(false)
                }}
                color="success"
                type="button"
                className="m-1"
              >
                Send to Allude <i className="bx bx-check-circle"></i>
              </Button>
            </div>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default FormWizard
