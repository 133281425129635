import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Nav,
  Table,
  Modal,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { baseurl, imgUrl } from "Baseurls"
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css"
import { ToastContainer, toast } from "react-toastify"

const FormWizard = () => {
  const history = useHistory()
  const [form, setform] = useState([])
  const [activeTab, setactiveTab] = useState(1)
  const [modal_small, setmodal_small] = useState(false)
  const [modal_smalll, setmodal_smalll] = useState(false)
  const [modal_smalll12, setmodal_smalll12] = useState(false)
  function tog_small() {
    setmodal_small(!modal_small)
    Clearmedicines()
  }

  function tog_smalll() {
    setmodal_smalll(!modal_smalll)
  }
  function tog_smalll12() {
    setmodal_smalll12(!modal_smalll12)
  }

  const [passedSteps, setPassedSteps] = useState([1])

  const [activeTab1, setactiveTab1] = useState("1")

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function toggleTab(tab) {
    if (activeTab === 1 && tab === 2) {
      // tog_small12();
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  // booking details
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [bookingslist, setbookingslist] = useState([])

  const [followps, setfollowps] = useState([])
  const [prescription, setprescription] = useState([])
  const [prescriptionbyid, setprescriptionbyid] = useState([])
  const [prescriptionbyidhealthIssues, setprescriptionbyidhealthIssues] =
    useState([])
  const [medicienss, setmedicienss] = useState([])

  const bookingId = sessionStorage.getItem("bookingid")

  const getBookings = () => {
    var token = datas
    const bodydata = {
      bookingId: bookingId,
    }
    axios
      .post(
        "https://api.alludeapp.com/v1/alludeapi/app/doctor/followup/getfollowpsId",
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setbookingslist(res?.data?.booking)
        setfollowps(res?.data?.followps)
        setprescriptionbyidhealthIssues(res?.data?.prescriptions?.healthIssues)
        setprescriptionbyid(res?.data?.prescriptions)
        setprescription(res?.data?.prescriptions?.alludeSuggestion)
        setmedicienss(res?.data?.prescriptions?.medicine)
        setform(res?.data?.booking)
      })
  }

  useEffect(() => {
    getBookings()
  }, [])

  const [medicine, setmedicine] = useState([])

  const [adminmedicine, setadminmedicine] = useState([])
  const [adminmedicinedosing, setadminmedicinedosing] = useState([])
  const [medicine1, setmedicine1] = useState([])

  const [medicine2, setmedicine2] = useState([])
  const [medicine12, setmedicine12] = useState([])
  const [medicines, setmedicines] = useState([])

  const handleChangemedicine = e => {
    const myData = { ...medicine }
    myData[e.target.name] = e.target.value
    setmedicine(myData)
  }

  const getbydata = data => {
    setmedicine12(data.medicines)
    setmodal_smalll(true)
  }
  const getbydata1 = data => {
    setmedicine2(data)
    setmodal_smalll12(true)
  }
  useEffect(() => {
    getadminMedicine()
    getmedicineDosings()
  }, [])

  const getadminMedicine = () => {
    var token = datas
    axios
      .post(
        baseurl + "getallmedicines",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setadminmedicine(res.data.medicines)
      })
  }

  const getmedicineDosings = () => {
    var token = datas
    axios
      .post(
        baseurl + "getactivemedicinedosing",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setadminmedicinedosing(res.data.medicineDosings)
      })
  }

  const addMedicines = e => {
    e.preventDefault()
    const bodydata = {
      medicineName: medicine.medicineName.split("(")[0].trim(),
      timesPerDay: medicine.timesPerDay,
      dosage: medicine.dosage,
      duration: medicine.duration,
      type: medicine.type,
      instruction: medicine.instruction,
    }
    const updatedata = [...medicine1, bodydata]
    setmedicine1(updatedata)

    const bodydata1 = {
      medicineName: medicine.medicineName,
      timesPerDay: medicine.timesPerDay,
      dosage: medicine.dosage,
      duration: medicine.duration,
      type: medicine.type,
      instruction: medicine.instruction,
    }
    const updatedata1 = [...medicines, bodydata1]
    setmedicines(updatedata1)

    setmodal_small(false)
  }

  const manageDelete = index => {
    const confirmBox = window.confirm("Do you want remove this medicine")
    if (confirmBox === true) {
      const delemed = medicine1.filter((_, i) => i !== index)
      setmedicine1(delemed)
    }
  }

  const Clearmedicines = () => {
    setmedicine({
      medicineName: "",
      timesPerDay: "",
      dosage: "",
      duration: "",
      instruction: "",
    })
  }

  const submitConsoldata = e => {
    e.preventDefault()

    var token = datas
    const bodydata = {
      bookingId: bookingId,
      followupnotes: gptdatas.join("\n"),
      medicines: medicine1,
    }
    axios
      .post(
        "https://api.alludeapp.com/v1/alludeapi/app/doctor/followup/addfollowUp",
        bodydata,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setTimeout(() => {
              history.push("/ViewBooking")
            }, 2000)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message)
          }
        }
      )
  }

  const [gptdatas, setgptdata] = useState([])

  const handleEdit = e => {
    const newValue = e.target.value
    setgptdata([newValue])
  }

  const Redirectcaht = () => {
    sessionStorage.setItem("bookingid", bookingId)
    history.push("/enquiries")
  }

  let investigationsContent = null

  // Check if medicine1 has data to populate the Investigations section
  if (medicienss.length !== 0) {
    investigationsContent = (
      <div className="mt-3">
        <h5 style={{ color: "black" }}>
          <b>
            <img
              src="https://api.alludeapp.com/uploads/rx.jpg"
              alt="test"
              height="30px"
            />
          </b>
        </h5>
        {medicienss.map((datas, indexs) => (
          <div key={indexs} className="mb-3" style={{ color: "black" }}>
            <b>
              {indexs + 1}.{datas.medicineName} ({datas.composition})
            </b>
            <br />
            <span>Frequency: {datas.timesPerDay}</span>
            <br />
            <span>Dosage: {datas.dosage}</span>
            <br />
            <span>
              Duration: {datas.duration} {datas.type}
            </span>
            <br />
            <span>Drug Instruction: {datas.instruction}</span>
          </div>
        ))}
        <b style={{ color: "black" }}>Investigations</b>
      </div>
    )
  } else if (medicienss.length == 0) {
    investigationsContent = <b style={{ color: "black" }}>Investigations</b>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Allude Doctor" breadcrumbItem="Prescription" />
          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Col lg="12">
                    <p className="text-end">
                      <b> Last Date : {form.date}</b>
                    </p>
                  </Col>

                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Previous
                            Prescription
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number ms-2">02</span>Follow Up
                            Prescription
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">03</span> New Prescription
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix mt-4">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <Form>
                            <div className="container">
                              <div>
                                {bookingslist.consultationType == "Call" ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        Redirectcaht()
                                      }}
                                      className="m-1"
                                      style={{ float: "right" }}
                                      size="sm"
                                      color="warning"
                                      title="View Chat"
                                    >
                                      <i className="bx bx-message-alt-dots"></i>
                                      View Chat
                                    </Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <div>
                                <p style={{ margin: "2px", color: "black" }}>
                                  <b>Name : </b> {form.userName}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Age/Sex : </b> {form.age}Yrs /{form.gender}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b> Weight/Ht : </b> {form.weight} (Kgs) /
                                  {form.height} (Cms)
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Location : </b>
                                  {form.address}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Date/Time : </b>
                                  {form.date}, {form.time}
                                </p>
                                {form.temperature == "" || form.grbs == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        margin: "2px",
                                        paddingTop: "2px",
                                        color: "black",
                                      }}
                                    >
                                      <b>Temperature/Grbs : </b>
                                      {form.temperature} / {form.grbs}
                                    </p>
                                  </>
                                )}
                              </div>

                              <div className="mt-4">
                                <>
                                  {prescriptionbyid.diagnosedProblem == "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <p style={{ color: "black" }}>
                                        <b>Diagnosed Case Of : </b>
                                      </p>
                                      {prescriptionbyid.diagnosedProblem}
                                    </>
                                  )}

                                  {prescriptionbyid.diagnosedMonth == "" ||
                                  prescriptionbyid.diagnosedYear == "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      <b>, Since:</b>
                                    </>
                                  )}

                                  {prescriptionbyid.diagnosedYear == "" ? (
                                    <></>
                                  ) : (
                                    <>{prescriptionbyid.diagnosedYear} years</>
                                  )}

                                  {prescriptionbyid.diagnosedMonth == "" ? (
                                    <></>
                                  ) : (
                                    <>
                                      , {prescriptionbyid.diagnosedMonth} months
                                    </>
                                  )}
                                </>
                              </div>

                              {prescriptionbyidhealthIssues[0]?.issue == "" &&
                              prescriptionbyidhealthIssues[0]?.days == "" ? (
                                <></>
                              ) : (
                                <>
                                  <div className="mt-3">
                                    <p style={{ color: "black" }}>
                                      <b>Complaints:</b>
                                    </p>
                                    <ol>
                                      {prescriptionbyidhealthIssues.map(
                                        (data, key) => (
                                          <>
                                            {data.issue != "" ||
                                            data.days != "" ? (
                                              <li key={key}>
                                                {data.issue == "" ? (
                                                  ""
                                                ) : (
                                                  <>
                                                    <b>{data.issue}</b>
                                                  </>
                                                )}

                                                {data.days == "" ? (
                                                  ""
                                                ) : (
                                                  <>Since {data.days} days</>
                                                )}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )
                                      )}
                                    </ol>
                                  </div>{" "}
                                </>
                              )}

                              {prescriptionbyid.briefDetails == "" ? (
                                <></>
                              ) : (
                                <>
                                  <div className="mt-3">
                                    <p style={{ color: "black" }}>
                                      <b>Brief Clinical Details:</b>
                                    </p>
                                    <p>{prescriptionbyid.briefDetails} </p>
                                  </div>
                                </>
                              )}

                              <div>
                                {/* {prescription.length == 0 ? (
                                  <></>
                                ) : (
                                  <>
                                    {prescription.map((data, index) => (
                                      <div key={index}>{data}</div>
                                    ))}{" "}
                                  </>
                                )}

                                {medicienss.length == 0 ? (
                                  <></>
                                ) : (
                                  <>
                                    <div>
                                      <h5
                                        style={{ color: "black" }}
                                        className="mt-3"
                                      >
                                        <b>
                                          <img
                                            src="https://api.alludeapp.com/uploads/rx.jpg"
                                            alt="test"
                                            height="30px"
                                          ></img>
                                        </b>
                                      </h5>

                                      {medicienss.map((data, index) => (
                                        <div
                                          key={index}
                                          className="mb-3"
                                          style={{ color: "black" }}
                                        >
                                          <b>
                                            {index + 1}.{data.medicineName} (
                                            {data.composition})
                                          </b>
                                          <br />
                                          <span>
                                            Frequency : {data.timesPerDay}
                                          </span>
                                          <br />
                                          <span>Dosage : {data.dosage}</span>
                                          <br />
                                          <span>
                                            Duration : {data.duration}{" "}
                                            {data.type}
                                          </span>
                                          <br />
                                          <span>
                                            Drug Instruction :{data.instruction}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </>
                                )} */}

                                {prescription?.map((data, index) => (
                                  <div
                                    key={index}
                                    style={{ paddingBottom: "10px" }}
                                  >
                                    {data ==
                                      "Diagnosis/Most probable diagnosis" ||
                                    data ==
                                      "Diagnosis/Most Probable Diagnosis" ||
                                    data == "Differential diagnoses" ||
                                    data == "Instructions" ||
                                    data == "Treatment" ? (
                                      <b
                                        style={{
                                          color: "black",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {data}
                                      </b>
                                    ) : data == "Investigations" ? (
                                      investigationsContent
                                    ) : (
                                      data
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>

                            <h5 className="text-primary mt-4">
                              Previous Followups :
                            </h5>
                            <Row>
                              <div className="table-responsive">
                                <Table
                                  hover
                                  className="table table-bordered mt-2"
                                >
                                  <thead>
                                    <tr className="text-center">
                                      <th>S.No</th>
                                      <th>Date</th>
                                      <th>Time</th>
                                      <th>User Name</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {followps.map((data, key) => (
                                      <tr key={key} className="text-center">
                                        <td>{key + 1}</td>
                                        <td>{data.date}</td>
                                        <td>{data.time}</td>
                                        <td>{data.userName}</td>

                                        <td>
                                          <Button
                                            className="m-1"
                                            color="success"
                                            size="sm"
                                            outline
                                            onClick={() => {
                                              getbydata1(data)
                                            }}
                                            title="View Prescription"
                                          >
                                            <i
                                              style={{ fontSize: "17px" }}
                                              className="fas fa-eye"
                                            ></i>
                                            View Prescription
                                          </Button>

                                          <Button
                                            className="m-1"
                                            color="warning"
                                            size="sm"
                                            outline
                                            onClick={() => {
                                              getbydata(data)
                                            }}
                                            title="View Medicines"
                                          >
                                            <i
                                              style={{ fontSize: "17px" }}
                                              className="fas fa-eye"
                                            ></i>
                                            View Medicines
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </Row>
                            <div className="text-end">
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                style={{ width: "100px", margin: "5px" }}
                                outline
                                color="danger"
                              >
                                Previous
                              </Button>
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                                style={{ width: "100px", margin: "5px" }}
                                outline
                                color="success"
                              >
                                Next
                              </Button>
                            </div>
                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <Form>
                            <div>
                              <Row>
                                <Col md="7">
                                  <Nav
                                    pills
                                    className="navtab-bg nav-justified"
                                  >
                                    <NavItem className="border border-primary rounded m-1">
                                      <NavLink
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        className={classnames({
                                          active: activeTab1 === "1",
                                        })}
                                        onClick={() => {
                                          toggle1("1")
                                        }}
                                      >
                                        Follow Up Notes
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="border border-primary rounded m-1">
                                      <NavLink
                                        style={{
                                          cursor: "pointer",
                                          padding: "10px",
                                        }}
                                        className={classnames({
                                          active: activeTab1 === "2",
                                        })}
                                        onClick={() => {
                                          toggle1("2")
                                        }}
                                      >
                                        Drugs Rx
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                </Col>
                              </Row>
                              <TabContent
                                activeTab={activeTab1}
                                className="p-3 text-muted"
                              >
                                <TabPane tabId="1">
                                  <div>
                                    <textarea
                                      className="form-control"
                                      value={gptdatas.join("\n")}
                                      rows={25}
                                      name="message"
                                      onChange={e => handleEdit(e)}
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <Row>
                                    <Col md="12">
                                      <div className="text-end">
                                        <Button
                                          onClick={tog_small}
                                          size="sm"
                                          color="info"
                                        >
                                          Add Medicine
                                          <i className="bx bx-plus-circle" />
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Table
                                    hover
                                    className="table table-bordered mt-2"
                                  >
                                    <thead>
                                      <tr className="text-center">
                                        <th>S.No</th>
                                        <th>Medicine name</th>
                                        <th>Frequency</th>
                                        <th>Dosage</th>
                                        <th>Duration</th>
                                        <th>Drug Instruction</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {medicine1.map((data, key) => (
                                        <tr key={key} className="text-center">
                                          <td>{key + 1}</td>
                                          <td>{data.medicineName}</td>
                                          <td>{data.timesPerDay}</td>
                                          <td>{data.dosage} </td>
                                          <td>
                                            {data.duration} {data.type}
                                            {data.duration == "0" ||
                                            data.duration == "1" ? (
                                              <></>
                                            ) : (
                                              <>s</>
                                            )}
                                          </td>
                                          <td>{data.instruction} </td>
                                          <td>
                                            <Button
                                              size="sm"
                                              color="danger"
                                              className="m-1"
                                              outline
                                              onClick={() => {
                                                manageDelete(key)
                                              }}
                                            >
                                              <i className="bx bx-trash"></i>
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </TabPane>
                              </TabContent>
                            </div>
                            <div className="text-end">
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                style={{ width: "100px", margin: "5px" }}
                                outline
                                color="danger"
                              >
                                Previous
                              </Button>
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab + 1)
                                }}
                                style={{ width: "100px", margin: "5px" }}
                                outline
                                color="primary"
                              >
                                Next
                              </Button>
                            </div>
                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>
                          <Form
                            onSubmit={e => {
                              submitConsoldata(e)
                            }}
                          >
                            <div className="container">
                              <div>
                                <p style={{ margin: "2px", color: "black" }}>
                                  <b>Name : </b> {form.userName}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Age/Sex : </b> {form.age}Yrs /{form.gender}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b> Weight/Ht : </b> {form.weight} (Kgs) /
                                  {form.height} (Cms)
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Location : </b>
                                  {form.address}
                                </p>
                                <p
                                  style={{
                                    margin: "2px",
                                    paddingTop: "2px",
                                    color: "black",
                                  }}
                                >
                                  <b>Date/Time : </b>
                                  {form.date}, {form.time}
                                </p>
                              </div>

                              {gptdatas.length == 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="pt-5">
                                    <h5 style={{ color: "black" }}>
                                      <b>Follow up Notes:</b>
                                    </h5>
                                    {gptdatas.map((data, index) => (
                                      <div key={index}>{data}</div>
                                    ))}
                                  </div>
                                </>
                              )}

                              {medicines.length == 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div>
                                    <h5
                                      style={{ color: "black" }}
                                      className="pt-5"
                                    >
                                      <b>
                                        <img
                                          src="https://api.alludeapp.com/uploads/rx.jpg"
                                          alt="test"
                                          height="30px"
                                        ></img>
                                      </b>
                                    </h5>
                                    {medicines.map((data, index) => (
                                      <div
                                        key={index}
                                        className="mb-3"
                                        style={{ color: "black" }}
                                      >
                                        <b>
                                          {index + 1}.{data.medicineName}
                                        </b>
                                        <br />
                                        <span>
                                          Frequency : {data.timesPerDay}
                                        </span>
                                        <br />
                                        <span>Dosage : {data.dosage}</span>
                                        <br />
                                        <span>
                                          Duration : {data.duration} {data.type}
                                          {data.duration == "0" ||
                                          data.duration == "1" ? (
                                            <></>
                                          ) : (
                                            <>s</>
                                          )}
                                        </span>
                                        <br />
                                        <span>
                                          Drug Instruction :{data.instruction}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="text-end">
                              <Button
                                onClick={() => {
                                  toggleTab(activeTab - 1)
                                }}
                                style={{ margin: "5px" }}
                                outline
                                color="danger"
                              >
                                Previous
                              </Button>
                              <Button
                                style={{ margin: "5px" }}
                                outline
                                color="success"
                              >
                                Send To Patient
                              </Button>
                            </div>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          size="md"
          isOpen={modal_small}
          toggle={() => {
            tog_small()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Medicine</h5>
            <button
              onClick={() => {
                setmodal_small(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form
              onSubmit={e => {
                addMedicines(e)
              }}
            >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <label htmlFor="browsers">Medicine Name:</label>
                    <input
                      required
                      placeholder="Medicine Name"
                      list="browsers"
                      id="browserChoice"
                      className="form-control"
                      name="medicineName"
                      value={medicine.medicineName}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    />
                    <datalist id="browsers">
                      {adminmedicine.map((data, key) => (
                        <option
                          key={key}
                          value={`${data.brandName} (${data.composition})`}
                        />
                      ))}
                    </datalist>
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <label htmlFor="browsers123">Frequency :</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter Frequency"
                      list="browsers123"
                      id="browserChoice"
                      className="form-control"
                      name="timesPerDay"
                      value={medicine.timesPerDay}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    />
                    <datalist id="browsers123">
                      {adminmedicinedosing.map((data, key) => (
                        <option key={key} value={data.dosing} />
                      ))}
                    </datalist>
                  </div>
                </Col>
                <Col md="12">
                  <div className="mb-3">
                    <label htmlFor="browsers1234">Dosage :</label>
                    <input
                      required
                      type="text"
                      placeholder="Enter Dosage"
                      list="browsers1234"
                      id="browserChoice"
                      className="form-control"
                      name="dosage"
                      value={medicine.dosage}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    />
                    <datalist id="browsers1234">
                      <option value="1ml" />
                      <option value="2ml" />
                      <option value="3ml" />
                      <option value="4ml" />
                      <option value="5ml" />
                      <option value="6ml" />
                      <option value="7ml" />
                      <option value="8ml" />
                      <option value="9ml" />
                      <option value="10ml" />
                    </datalist>
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <Label>
                      Duration <span className="text-danger">*</span>
                    </Label>
                    <Input
                      required
                      name="duration"
                      value={medicine.duration}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                      type="text"
                      placeholder="Enter Duration"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="mb-3">
                    <Label>
                      Duration Type <span className="text-danger">*</span>
                    </Label>
                    <select
                      className="form-select"
                      required
                      name="type"
                      value={medicine.type}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Day">Day</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                    </select>
                  </div>
                </Col>

                <Col md="12">
                  <div className="mb-3">
                    <Label>Drug Instruction</Label>
                    <textarea
                      name="instruction"
                      value={medicine.instruction}
                      onChange={e => {
                        handleChangemedicine(e)
                      }}
                      className="form-control"
                      type="text"
                      placeholder="Enter Drug Instruction"
                    />
                  </div>
                </Col>
              </Row>
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    setmodal_small(false)
                  }}
                  color="danger"
                  type="button"
                  className="m-1"
                >
                  Cancel <i className="bx bx-x-circle"></i>
                </Button>
                <Button color="success" type="submit" className="m-1">
                  Submit <i className="bx bx-check-circle"></i>
                </Button>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_smalll}
          toggle={() => {
            tog_smalll()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Medicines</h5>
            <button
              onClick={() => {
                setmodal_smalll(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div style={{ width: "100%" }}>
                {medicine12.map((data, index) => (
                  <div key={index} className="mb-3" style={{ color: "black" }}>
                    <b>
                      {index + 1}.{data.medicineName}
                    </b>
                    <br />
                    <span>Frequency : {data.timesPerDay}</span>
                    <br />
                    <span>Dosage : {data.dosage}</span>
                    <br />
                    <span>
                      Duration : {data.duration} {data.type}
                    </span>
                    <br />
                    <span>Drug Instruction : {data.instruction}</span>
                  </div>
                ))}
              </div>
            </div>

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_smalll(false)
                }}
                color="danger"
                type="button"
                className="m-1"
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          size="xl"
          isOpen={modal_smalll12}
          toggle={() => {
            tog_smalll12()
          }}
          centered
        >
          <div className="modal-header">
            <h5>Prescription</h5>
            <button
              onClick={() => {
                setmodal_smalll12(false)
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              {medicine2.prescription == undefined ||
              medicine2.prescription == "" ? (
                <h1 className="text-center">No Data..</h1>
              ) : (
                <iframe
                  style={{ width: "100%", height: "400px" }}
                  src={imgUrl + medicine2.prescription}
                />
              )}
            </div>

            <div style={{ float: "right" }}>
              <Button
                onClick={() => {
                  setmodal_smalll12(false)
                }}
                color="danger"
                type="button"
                className="m-1"
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </Modal>

        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default FormWizard
